import React from "react";
import styled from "styled-components";
import Icon from "../svg-loader";
import LinkExternal from "../john-gatsby-helpers/link-external";
import Link from "../john-gatsby-helpers/link";
import { colorPlates, device } from "../../style/theme";
import { makeHover } from "../../style/base-styles";
import { PillWrapper } from "./pill-wrapper";

function ButtonInner({ children, text, icon }) {
  return (
    <>
      {text || children}
      {icon && <Icon aria-hidden {...icon} />}
    </>
  );
}

export default function Button({
  children,
  text,
  to,
  href,
  type,
  forwardClick,
  icon = "ArrowRight",
  external,
  color = "paper",
  onClick,
  className,
  "data-testid": dataTestId,
  ...rest
}) {
  if (href || external || type === "onClick") {
    return (
      <ButtonWrapper
        className={`button ${className}`}
        color={color}
        tabIndex="-1"
        role="button"
        aria-labelledby={text}
        {...rest}
      >
        <LinkExternal
          data-testid={dataTestId}
          href={href}
          onClick={onClick}
          target="_blank"
          rel="noopener noreferrer"
          tabIndex="0"
        >
          <ButtonInner
            text={text}
            icon={{ icon, color: colorPlates[color]?.icon }}
          >
            {children}
          </ButtonInner>
        </LinkExternal>
      </ButtonWrapper>
    );
  }
  if (type === "submit") {
    return (
      <ButtonWrapper
        className={`button ${className}`}
        color={color}
        tabIndex="-1"
        role="button"
        aria-labelledby={text}
        {...rest}
      >
        <button
          data-testid={dataTestId}
          type="submit"
          onClick={forwardClick}
          tabIndex="0"
        >
          <ButtonInner
            text={text}
            icon={{ icon, color: colorPlates[color].icon }}
          >
            {children}
          </ButtonInner>
        </button>
      </ButtonWrapper>
    );
  }
  return (
    <ButtonWrapper
      className={`button ${className}`}
      color={color}
      tabIndex="-1"
      role="button"
      aria-labelledby={text}
    >
      <Link to={to} tabIndex="0" data-testid={dataTestId}>
        <ButtonInner
          text={text}
          icon={{ icon, color: colorPlates[color].icon }}
        >
          {children}
        </ButtonInner>
      </Link>
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled(PillWrapper)`
  font-family: "DomaineSansTextBold";
  font-size: ${({ theme }) => theme.fonts.titleLarge};
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  z-index: 0;
  cursor: pointer;
  white-space: nowrap;

  ${makeHover`
    transform: scale(1.01);

    &:after, &:before {
      transform: scale(1.01);
    }

    a {
      text-decoration: none !important;
    }

    .icon-wrap {
      transform: translate3d(0.25rem, -0.1em, 0);
    }
  `}

  .icon-wrap {
    transform: translate3d(0, -0.1em, 0);
    transition: transform ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css},
      opacity ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css};
  }

  button {
    z-index: 1;
    border: none;
    font: inherit;
    font-size: ${({ theme }) => theme.fonts.titleLarge};
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    background-color: transparent;
  }

  a,
  button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ color }) => colorPlates[color].foreground};
    appearance: none;
    padding: 0 1.5rem;

    @media only screen and ${device.tablet} {
      padding: 0 3rem;
    }

    &:after {
      display: none;
    }

    svg path {
      fill: ${({ color }) => colorPlates[color].icon} !important;
    }
  }

  & * {
    margin: 0;
    text-decoration: none;
  }
`;
