import React from "react";
import styled from "styled-components";
import { colorPlates, device } from "../../style/theme";
import Box from "../john-motion/motion-box";

export const PillWrapper = styled(Box)`
  display: inline-grid;
  border-radius: 0.75rem;
  height: 2.5rem;
  position: relative;
  align-items: center;
  justify-content: center;
  appearance: none;
  padding: 0 1.5rem;
  grid-auto-flow: column;
  background-color: transparent;
  color: ${({ color }) => colorPlates[color].foreground};
  transition: transform ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css},
    opacity ${({ theme }) => theme.animation.duration[100].css}
      ${({ theme }) => theme.animation.timingFunction.css};

  @media only screen and ${device.tablet} {
    height: 3.5rem;
    border-radius: 1rem;
    padding: 0 3rem;
  }

  &:active {
    transform: scale(0.99);

    &:after,
    &:before {
      transform: scale(0.99);
    }
  }

  &:focus-visible {
    outline: none;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-radius: 1rem;
    border: solid 2px ${({ color }) => colorPlates[color].border};
    background-color: ${({ color }) => colorPlates[color].background};
    color: ${({ color }) => colorPlates[color].foreground};
    transition: transform ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css},
      opacity ${({ theme }) => theme.animation.duration[100].css}
        ${({ theme }) => theme.animation.timingFunction.css};
    will-change: transform;
  }

  &:before {
    width: calc(100% - 8px);
    height: 100%;
    top: -2px;
    left: 2px;
    bottom: 0;
    z-index: 0;
  }

  &:after {
    width: calc(100% - 8px);
    height: calc(100% + 2px);
    top: -3px;
    left: 2px;
    bottom: -3px;
    z-index: -1;
  }
`;
